body {
  margin: 0;
  font-size: 1.4em;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a { color: red;}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: white;
  background: #000;
  padding: 0.3em .5em;
}

pre {
  background: #000;
  color: white;
  padding: 1em;
  overflow-x: auto;
}

*::-moz-selection { background: red}
*::selection { background: red; }

pre code {
  background: none !important;
  padding: 0;
}

.flex { display: flex; }
.bold { font-weight: bold; }
.italic { font-style: italic; }

* { box-sizing: border-box; }

ul li.task-list-item {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -1em;
  vertical-align: middle;
  align-items: center;
  display: flex;
}

ul li.task-list-item input {
  margin-right: .5em;
}
.app {
  background: white;
  margin: 0;
  height: 100vh;
  flex-direction: column;
  justify-items: stretch;
  padding: 0;
}

.editor {
  height: 100%;
  max-height: 100%;
  padding: 2em;
  overflow: auto;
  width: 100%;
}

nav {
  background: #fff;
  padding: 0;
  z-index: 1;
  max-height: 0;
  transform: scale(0.7) translateX(-20%) translateY(-30%);
  overflow: hidden;
  position: relative;
  transition: 350ms;
}

main {
  position: relative;
}

.menu-toggled nav {
  max-height: 100%;
  transform: scale(1) translateX(0%) translateY(0%);
}

#menu-button {
  display: inline-block;
  border: none;
  background: none;
  outline: none;
  text-align: left;
  z-index: 999;
  font-size: 1em;
  cursor: pointer;
  padding: .5em 1em;
}

#menu-button:active {
  background: none;
}

.menu-toggled #menu-button {
}

nav ul {
  padding: 0;
  margin: 0;
  height: auto;
}
nav h3 {
  margin: 0;
  line-height: inherit;
  font-size: 1em;
}

nav ul li {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0 1em;
}

nav a {
  display: block;
  color: #000;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
  font-style: italic;
}

.raw-input {
  background: #f0f0f0;
  min-height: 30%;
}

.no-padding {
  padding: 0;
}